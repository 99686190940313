import React from "react"
import Layout from "../components/layout"
import { PageHeader } from "../components/PageHeader"
import SEO from "../components/seo"
import PortableText from "@sanity/block-content-to-react"
import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import { ContactBox } from "../components/ContactBox"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import Figure from "react-bootstrap/Figure"
import imageUrlBuilder from "@sanity/image-url"
import { graphql } from "gatsby"
const Wrapper = styled.div`
  padding: 60px 20px;
  margin: 0 auto;
  max-width: 1000px;

  .card-body {
    font-size: 16px;
  }
`
const CardHeader = styled(Card.Header)`
  font-weight: bolder;
  cursor: pointer;
`
const urlFor = source =>
  imageUrlBuilder({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }).image(source)
const serializers = {
  types: {
    altImage: props => (
      <Figure style={{ marginRight: 20 }}>
        <a
          key={props.node.alt}
          data-attribute="SRL"
          href={urlFor(props.node.asset)
            .width(1000)
            .quality(80)
            .url()}
        >
          <Figure.Image
            src={urlFor(props.node.asset)
              .width(300)
              .quality(80)
              .url()}
            alt={props.node.alt}
          />
        </a>
        <Figure.Caption>{props.node.alt}</Figure.Caption>
      </Figure>
    ),
  },
}
export const query = graphql`
  query {
    allSanityRepairs {
      edges {
        node {
          id
          _rawRepair
          title
        }
      }
    }
  }
`
const Faq = ({ data }) => {
  const { edges } = data.allSanityRepairs
  return (
    <Layout>
      <SEO
        title="Przykładowe naprawy"
        description="Przykładowe naprawy wykonywane u nas w warsztacie."
      />
      <PageHeader>Przykładowe naprawy</PageHeader>
      <Wrapper>
        <Accordion defaultActiveKey="0">
          {edges.length > 0 &&
            edges.map((item, index) => (
              <Card key={index}>
                <Accordion.Toggle as={CardHeader} eventKey={index}>
                  {item.node.title}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body>
                    <SimpleReactLightbox>
                      <SRLWrapper>
                        <PortableText
                          blocks={item.node._rawRepair}
                          serializers={serializers}
                        />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
        </Accordion>
        <ContactBox>
          <h4>Nie czekaj aż auto się zepsuje. Zadzwoń i umów wizytę</h4>
          <span>
            Telefon:<a href="tel:+123863336"> 12 386 33 36 &nbsp;</a>
          </span>
        </ContactBox>
      </Wrapper>
    </Layout>
  )
}

export default Faq
